<template>
  <div>
    <div class="box_nav">
      <div class="mar">
        <p class="title">关于赢信汇通（雅安）智能制造有限公司</p>
        <p class="eTitle">高端智能交通装备制造一期项目水土保持设施自主验收公示说明</p>
        <p class="content">
         根据水土保持法律法规和《四川省水利厅转发关于加强事中事后监管规范生产建设项目水土保持设施自主验收的通知》（川水函[2018]887号），赢信汇通（雅安）智能制造有限公司于2022年在雅安市召开了赢信汇通（雅安）智能制造有限公司高端智能交通装备制造一期项目水土保持设施竣工验收会议，参加验收的有验收报告编制单位、监测单位、监理单位、方案编制单位、施工单位的代表，会议成立了验收组。验收组认为赢信汇通（雅安）智能制造有限公司高端智能交通装备制造一期项目实施过程中落实了水土保持方案及批复文件要求，完成了水土流失预防和治理任务，水土流失防治指标达到水土保持方案确定的目标值，符合水土保持设施验收的条件。验收组一致同意赢信汇通（雅安）智能制造有限公司高端智能交通装备制造一期项目水土保持设施通过验收。
        </p>
        <p style="text-indent:30px;padding:20px 0">
          现将验收情况在建设单位上级单位网站（赢信汇通集团官网）上公示。
        </p>
        <p class="formula">公示期：2022年1月24日至2022年2月23日</p>
        <p class="formula">公示单位联系人及电话：杨工/15281297890</p>
        <p class="formula">报备单位：雅安市名山区水利局</p>
        <p class="formula">报备单位电话：0835-3222501</p>
        <div class="nav_padd">
           <div class="padd">
          <el-link
            href="http://system.yxht.net/%E9%AB%98%E7%AB%AF%E6%99%BA%E8%83%BD%E4%BA%A4%E9%80%9A%E8%A3%85%E5%A4%87%E5%88%B6%E9%80%A0%E4%B8%80%E6%9C%9F%E9%A1%B9%E7%9B%AE%E7%9B%91%E6%B5%8B%E6%80%BB%E7%BB%93%E6%8A%A5%E5%91%8A.pdf"
            target="_blank"
            type="primary"
            >项目水土保持设施验收鉴定书.pdf</el-link
          >
        </div>
        <div class="padd">
          <el-link
            href="http://system.yxht.net/%E9%AB%98%E7%AB%AF%E6%99%BA%E8%83%BD%E4%BA%A4%E9%80%9A%E8%A3%85%E5%A4%87%E5%88%B6%E9%80%A0%E4%B8%80%E6%9C%9F%E9%A1%B9%E7%9B%AE%E7%9B%91%E6%B5%8B%E6%80%BB%E7%BB%93%E6%8A%A5%E5%91%8A.pdf"
            target="_blank"
            type="primary"
            >高端智能交通装备制造一期项目验收报告.pdf</el-link
          >
        </div>
        <div class="padd">
          <el-link
            href="http://system.yxht.net/%E9%AB%98%E7%AB%AF%E6%99%BA%E8%83%BD%E4%BA%A4%E9%80%9A%E8%A3%85%E5%A4%87%E5%88%B6%E9%80%A0%E4%B8%80%E6%9C%9F%E9%A1%B9%E7%9B%AE%E9%AA%8C%E6%94%B6%E6%8A%A5%E5%91%8A.pdf"
            target="_blank"
            type="primary"
            >高端智能交通装备制造一期项目监测总结报告.pdf</el-link
          >
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  watch: {},
  computed: {},
};
</script>

<style scoped lang='scss'>
.box_nav {
  width: 100%;
  margin-top: 40px;
  .mar {
    width: 1240px;
    margin: 0 auto;
    background-color: #fff;
    .title {
      font-size: 25px;
      color: #e13834;
      text-align: center;
    }
    .eTitle {
      font-size: 17px;
      color: #e13834;
      text-align: center;
      padding: 20px 0;
    }
    .content {
      font-size: 14px;
      text-indent: 25px;
      line-height: 30px;
    }
    .nav_padd{
      padding-top: 40px;
    }
    .padd{
      padding: 10px 0;
    }
    .formula{
      padding: 10px 0;
    }
  }
}
</style>